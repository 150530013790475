import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { InlineCode } from '../../components/inline-code/inline-code';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Do you use the keyboard to surf the web? If so, then you'll appreciate having a clearly visible focus indicator.
This accessibility feature is especially important for people with motor impairments who rely on a keyboard, instead
of a mouse, to navigate a web page.`}</p>
    <p>{`In general, I recommend using the `}<InlineCode mdxType="InlineCode">{`:focus-visible`}</InlineCode>{` pseudo-class to define custom focus
indicators. Check out my article `}<a parentName="p" {...{
        "href": "/highly-visible-keyboard-focus.en"
      }}>{`“Provide highly visible Keyboard Focus with :focus-visible”`}</a>{`
for more information. But how do you account for different color themes and dark mode?`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAQFAgP/xAAVAQEBAAAAAAAAAAAAAAAAAAACAf/aAAwDAQACEAMQAAAB1xWXKuE8U//EABkQAAIDAQAAAAAAAAAAAAAAAAECAAMRBP/aAAgBAQABBQJ2yterJXcHU2sZs5xqf//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABoQAAICAwAAAAAAAAAAAAAAAAABERIQQXH/2gAIAQEABj8Cl6IqTao08Pp//8QAGxAAAgMAAwAAAAAAAAAAAAAAABEBITFBYYH/2gAIAQEAAT8hqzYrt6M9zSKVKYOlDBM8j//aAAwDAQACAAMAAAAQG8//xAAWEQEBAQAAAAAAAAAAAAAAAAAAEQH/2gAIAQMBAT8QiY//xAAWEQEBAQAAAAAAAAAAAAAAAAAAEQH/2gAIAQIBAT8Qq6//xAAcEAEAAgMAAwAAAAAAAAAAAAABACERMUFRYXH/2gAIAQEAAT8QBGe3wzBkI2mWYWthVNfZt5lBiZDUtslmD2vhP//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Closeup of a person's hands typing on a laptop keyboard",
          "title": "Closeup of a person's hands typing on a laptop keyboard",
          "src": "/static/58a5de4c0dd0ba8473945cd4519d5b1d/e5166/pexels-cottonbro-keyboard.jpg",
          "srcSet": ["/static/58a5de4c0dd0ba8473945cd4519d5b1d/f93b5/pexels-cottonbro-keyboard.jpg 300w", "/static/58a5de4c0dd0ba8473945cd4519d5b1d/b4294/pexels-cottonbro-keyboard.jpg 600w", "/static/58a5de4c0dd0ba8473945cd4519d5b1d/e5166/pexels-cottonbro-keyboard.jpg 1200w", "/static/58a5de4c0dd0ba8473945cd4519d5b1d/b17f8/pexels-cottonbro-keyboard.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Photo: © cottonbro / pexels.com`}</em></p>
    <p>{`The elegant solution is: Define a two-color focus indicator that has sufficient contrast against any solid background
color. The `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG22/Techniques/css/C40"
      }}>{`W3C's Technique C40`}</a>{` recommends this solution as a possible
way to conform with the WCAG criteria `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG22/Understanding/focus-visible"
      }}>{`2.4.7: Focus Visible`}</a>{`
and `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG22/Understanding/focus-appearance"
      }}>{`2.4.13: Focus Appearance`}</a>{`.`}</p>
    <h2>{`Demo: Two-Color Focus Indicator`}</h2>
    <p>{`Here's my `}<a parentName="p" {...{
        "href": "https://codepen.io/alexlehner86/pen/wvZJbBd"
      }}>{`demo`}</a>{` of an accessible two-color focus indicator. You can toggle
between light and dark mode using the checkbox labelled “Dark Mode”. Focus on the button and the link with the tab key and
observe the focus indicator:`}</p>
    <iframe title="Two-color focus indicator" src="https://codepen.io/alexlehner86/embed/wvZJbBd?default-tab=result" loading="lazy">
    See the Pen <a href="https://codepen.io/alexlehner86/pen/wvZJbBd">
    Two-color focus indicator</a> by Alexander Lehner (<a href="https://codepen.io/alexlehner86">@alexlehner86</a>)
    on <a href="https://codepen.io">CodePen</a>.
    </iframe>
    <p>{`How does this work?`}</p>
    <h2>{`The (not so) secret CSS ingredients`}</h2>
    <p>{`To create the two-color focus indicator, you simply combine the CSS properties `}<InlineCode mdxType="InlineCode">{`outline`}</InlineCode>{`
and `}<InlineCode mdxType="InlineCode">{`box-shadow`}</InlineCode>{` to draw a light and dark line around the focused element.`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`:is(a[href], button):focus-visible {
    /* inner indicator */
    outline: 3px #fff solid;
    outline-offset: 0;
    /* outer indicator */
    box-shadow: 0 0 0 5px #000;
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`This way, one of the lines always provides sufficient contrast with the current background color. In my demo, I used
a simple combination of pure white and black. But you can also use other colors, as the W3C technique explains:`}</p>
    <blockquote>
      <p parentName="blockquote">{`As long as the two indicator colors have a `}<strong>{`contrast ratio of at least 9:1 with each other`}</strong>{`, at least
one of the two colors is guaranteed to meet 3:1 contrast with any solid background color.`}</p>
    </blockquote>
    <p>{`The two-color focus indicator even works in forced colors mode. If you're not familiar with this feature, then read
my article `}<a parentName="p" {...{
        "href": "/forced-colors-mode.en/"
      }}>{`“CSS tweaks for better visibility in Forced Colors Mode”`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      